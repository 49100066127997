<div class="card-wizard">
  @for (step of steps; track step.stepNumber; let first = $first) {
    <div>
      @if (!first) {
        <div class="step-connector"></div>
      }
      <ng-container [ngTemplateOutlet]="step.content" />
    </div>
  }
</div>
