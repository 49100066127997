import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, input, signal } from '@angular/core';

@Component({
  selector: 'ln-hidden-info-label',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './hidden-info-label.component.html',
  styleUrl: './hidden-info-label.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HiddenInfoLabelComponent {
  hiddenText = input('');
  displayText = input('***');
  canRevealHiddenInfo = input(false);
  infoRevealed = signal(false);

  onLabelClicked() {
    if (this.canRevealHiddenInfo()) {
      this.infoRevealed.set(true);
    }
  }
}
