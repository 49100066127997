/* eslint-disable @angular-eslint/no-output-native */

import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  forwardRef,
  HostBinding,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgOption, NgSelectModule } from '@ng-select/ng-select';

import { ISelectOption } from '../../interfaces';
import { KebabcasePipe } from '../../pipes/kebabcase/kebabcase.pipe';
import { TextTruncatePipe } from '../../pipes/text-truncate/text-truncate.pipe';
import { DropdownSelectComponentBase } from '../dropdown-select/base/dropdown-select-base.component';

@Component({
  selector: 'locumsnest-dropdown-grouping-select',
  templateUrl: './dropdown-grouping-select.component.html',
  styleUrls: ['./dropdown-grouping-select.component.scss'],
  standalone: true,
  imports: [NgSelectModule, CommonModule, FormsModule, KebabcasePipe, TextTruncatePipe],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DropdownGroupingSelectComponent),
      multi: true,
    },
  ],
})
export class DropdownGroupingSelectComponent<T extends string | boolean | number>
  extends DropdownSelectComponentBase<T>
  implements ControlValueAccessor, OnDestroy
{
  @Input() idx: string;
  @Input() isDisabled = false;
  @Input() groupingProperty: string;
  @Input() placeholder = 'Select';
  @Input() clearable = false;
  @Input() hideSelected = false;
  @Input() clearButtonPosition: 'left' | 'right' = 'left';
  @Input() multiple = false;
  @Input() selectableGroup = false;
  @Input() selectableGroupAsModel = false;
  @Input() maxDisplayItems = 1;
  @Input() removeNonOptionValues = false;
  @Input() extraClass = 'white-theme';
  @Input() clearOnDestroy = false;
  @Input() loading = false;
  @Input() virtualScroll = false;
  @Input() searchable = true;

  @Output() close = new EventEmitter();
  @Output() clear = new EventEmitter();
  @Output() clearAll = new EventEmitter<void>();
  @Output() removeOne = new EventEmitter<number | string>();
  @Output() addOne = new EventEmitter<number | string>();
  @Output() select = new EventEmitter<T>();
  @Output() search = new EventEmitter<string>();
  @Output() scrollToEnd = new EventEmitter<void>();

  @HostBinding('class') get themeClass() {
    return this.extraClass;
  }

  @Input() @HostBinding('class.has-error') hasError: boolean;

  onOptionSelect(_: NgOption) {
    this.select.emit(this.value);
  }

  onClose() {
    this.close.emit();
  }

  onClear() {
    this.clear.emit();
  }

  onClick(event: Event) {
    event.stopPropagation();
  }

  onClearAll() {
    this.clearAll.emit();
  }

  onAddOne(option: ISelectOption) {
    this.addOne.emit(option.id);
  }

  onRemoveOne(option: ISelectOption) {
    this.removeOne.emit(option.id);
  }

  onSearch({ term }: { term: string; items: T[] }) {
    this.search.emit(term);
  }

  onScrollToEnd() {
    this.scrollToEnd.emit();
  }
  ngOnDestroy() {
    if (this.clearOnDestroy) {
      this.value = null;
    }
  }
}
