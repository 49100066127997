import { NgClass, NgStyle } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

type FontWeight = 'regular' | 'bold';
type FontSize = 'normal' | 'medium';
type CardType = 'info' | 'approved' | 'cancelled' | 'incomplete' | 'report';

@Component({
  selector: 'locumsnest-detail-card',
  templateUrl: './detail-card.component.html',
  styleUrls: ['./detail-card.component.scss'],
  standalone: true,
  imports: [NgStyle, NgClass],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DetailCardComponent {
  @Input() cardType: CardType;
  @Input() fontWeight: FontWeight = 'regular';
  @Input() size: FontSize = 'normal';
  @Input() showStatus = true;
  @Input() name: string;
  @Input() details: string;
  @Input() showDetails = true;
  @Input() title: string;
  @Input() statusDisplay: string;
  @Input() fullWidth = false;
}
