import {
  ChangeDetectionStrategy,
  Component,
  computed,
  EventEmitter,
  input,
  Output,
} from '@angular/core';

import { DATE_FORMAT, TIME_FORMAT } from '@locumsnest/core/src/lib/types/constants';

import { IconTipComponent } from '../../atoms/icon-tip/icon-tip.component';
import { TagLabelComponent } from '../../atoms/tag-label/tag-label.component';
import { DatePipe } from '../../pipes/date/date.pipe';

export interface IJobListingMultiBid {
  id: number;
  startTime: Date;
  endTime: Date;
  hasAlert: boolean;
  selectedListing: boolean;
}

@Component({
  selector: 'ln-multi-bid',
  standalone: true,
  imports: [TagLabelComponent, IconTipComponent, DatePipe],
  templateUrl: './multi-bid.component.html',
  styleUrl: './multi-bid.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MultiBidComponent {
  showLeftArrow = input(false);
  showRightArrow = input(false);
  isDisabled = input(false);
  hasSelectedListing = input(false);
  stepTwoActive = input(false);
  title = input('');

  @Output() jobListingSelected = new EventEmitter<number>();
  @Output() jobListingRemoved = new EventEmitter<number>();

  jobListings = input<IJobListingMultiBid[]>([]);

  count = computed(() => this.jobListings().length);
  errorCount = computed(() => this.jobListings().filter((listing) => listing.hasAlert).length);

  timeFormat = TIME_FORMAT;
  dateFormat = DATE_FORMAT;

  onJobListingSelected(id: number) {
    this.jobListingSelected.emit(id);
  }

  onJobListingRemove(event: Event, id: number) {
    event.stopPropagation();
    this.jobListingRemoved.emit(id);
  }
}
