import { ChangeDetectionStrategy, Component, input } from '@angular/core';

import { DatePipe } from '../../pipes/date/date.pipe';
import { IconComponent } from '../icon/icon.component';

@Component({
  selector: 'ln-block-label',
  standalone: true,
  imports: [DatePipe, IconComponent],
  templateUrl: './block-label.component.html',
  styleUrl: './block-label.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    '[class.is-active]': 'isActive()',
  },
})
export class BlockLabelComponent {
  numberOfShifts = input<number>();
  startDate = input<Date>();
  endDate = input<Date>();
  isActive = input(false);
}
