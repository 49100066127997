import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { NgSelectModule } from '@ng-select/ng-select';
import { TippyDirective } from '@ngneat/helipopper';
import { ngfModule } from 'angular-file';
import { NgrxFormsModule } from 'ngrx-forms';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

import { AtomsModule } from '../atoms/atoms.module';
import { AddAnchorBlankDirective } from '../directives/add-anchor-blank/add-anchor-blank.directive';
import { ScrollToModule } from '../directives/scroll-to/scroll-to.module';
import { PipesModule } from '../pipes/pipes.module';
import { SafePipe } from '../pipes/safe/safe.pipe';
import { AddBarComponent } from './add-bar/add-bar.component';
import { AdditionListItemComponent } from './addition-list-item/addition-list-item.component';
import { CalendarComponent } from './calendar/calendar.component';
import { CardWizardStepComponent } from './card-wizard-step/card-wizard-step.component';
import { CardWizardComponent } from './card-wizard/card-wizard.component';
import { CarouselPanelComponent } from './carousel-panel/locumsnest-carousel-panel';
import { ChoiceSectionComponent } from './choice-section/choice-section.component';
import { CompactEventCalendarComponent } from './compact-event-calendar/compact-event-calendar.component';
import { DatePickerComponent } from './date-picker/date-picker.component';
import { DateRangeComponent } from './date-range/date-range.component';
import { DateTimePickerComponent } from './date-time-picker/date-time-picker.component';
import { DetailCardComponent } from './detail-card/detail-card.component';
import { DialogComponent } from './dialog/dialog.component';
import { DropdownGroupingSelectComponent } from './dropdown-grouping-select/dropdown-grouping-select.component';
import { DropdownSelectComponent } from './dropdown-select/dropdown-select.component';
import { EditableDisplayAreaComponent } from './editable-display-area/editable-display-area.component';
import { EditorComponent } from './editor/editor.component';
import { ExpandableInputSectionComponent } from './expandable-input-section/expandable-input-section.component';
import { ExpandableSectionComponent } from './expandable-section/expandable-section.component';
import { FileDownloadComponent } from './file-download/file-download.component';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { TEMP_FILE_UPLOAD_SERVICE } from './file-upload/opaque-tokens';
import { TempFileUploadService } from './file-upload/temp-file-upload.service';
import { FileComponent } from './file/file.component';
import {
  FormGroupListComponent,
  FormGroupListItemComponent,
} from './form-group-list/form-group-list.component';
import { InfoCardListComponent } from './info-card-list/info-card-list.component';
import { InfoCardComponent } from './info-card/info-card.component';
import { InternalNotesComponent } from './internal-notes/internal-notes.component';
import { MessageListItemComponent } from './message-list-item/message-list-item.component';
import { MessageComponent } from './message/message.component';
import { MessengerMessageComponent } from './messenger-message/messenger-message.component';
import { ModalComponent } from './modal/modal.component';
import { NotesComponent } from './notes/notes.component';
import { NotificationAlertComponent } from './notification-alert/notification-alert.component';
import { OptionalWidgetAreaComponent } from './optional-widget-area/optional-widget-area.component';
import { PaginationBarComponent } from './pagination-bar/pagination-bar.component';
import { ProfileBadgeComponent } from './profile-badge/profile-badge.component';
import { ButtonGroupRadioButtonComponent } from './radio-button-group/button-group-radio-button.component';
import { RadioButtonGroupComponent } from './radio-button-group/radio-button-group.component';
import { RateViolationsReasonSelectComponent } from './rate-violations-reason-select/rate-violations-reason-select.component';
import { RecentActivityComponent } from './recent-activity/recent-activity.component';
import { SectionComponent } from './section/section.component';
import { ShadowCollapseContainerComponent } from './shadow-collapse-container/shadow-collapse-container.component';
import { ShiftDescriptionFilesComponent } from './shift-description-files/shift-description-files.component';
import { ShiftGradeRateComponent } from './shift-grade-rate/shift-grade-rate.component';
import { SlidePanelContainerComponent } from './slide-panel-container/locumsnest-slide-panel-container';
import { SlidePanelComponent } from './slide-panel/locumsnest-slide-panel';
import { StepperComponent } from './stepper/stepper.component';
import { StructuredInputComponent } from './structured-input/structured-input.component';
import { TableComponent } from './table/table.component';
import { TabHeaderDirective } from './tabs/tab-header.directive';
import { TabDirective } from './tabs/tab.directive';
import { TabsComponent } from './tabs/tabs.component';
import { TitleWithButtonComponent } from './title-with-button/title-with-button.component';
import { UserStatusBarComponent } from './user-status-bar/user-status-bar.component';
import { WarningBoxComponent } from './warning-box/warning-box.component';
import { WizardStepComponent } from './wizard-step/wizard-step.component';

@NgModule({
  imports: [
    CommonModule,
    PipesModule,
    ReactiveFormsModule,
    FormsModule,
    NgSelectModule,
    ngfModule,
    AtomsModule,
    NgrxFormsModule,
    InfiniteScrollModule,
    RouterModule,
    CKEditorModule,
    ScrollToModule,
    TippyDirective,
    SafePipe,
    DropdownGroupingSelectComponent,
    TabDirective,
    TabHeaderDirective,
    AddAnchorBlankDirective,
    ButtonGroupRadioButtonComponent,
    RadioButtonGroupComponent,
    FileComponent,
    TableComponent,
    PaginationBarComponent,
    DatePickerComponent,
    CalendarComponent,
    CompactEventCalendarComponent,
    SlidePanelComponent,
    OptionalWidgetAreaComponent,
    WarningBoxComponent,
    FileUploadComponent,
    DetailCardComponent,
    StructuredInputComponent,
    DialogComponent,
    CardWizardStepComponent,
    CardWizardComponent,
  ],
  declarations: [
    ShiftDescriptionFilesComponent,
    ShiftGradeRateComponent,
    WizardStepComponent,
    DropdownSelectComponent,
    TitleWithButtonComponent,
    ExpandableInputSectionComponent,
    ExpandableSectionComponent,
    UserStatusBarComponent,
    SectionComponent,
    TabsComponent,
    AddBarComponent,
    NotificationAlertComponent,
    DateRangeComponent,
    ProfileBadgeComponent,
    MessageComponent,
    NotesComponent,
    EditableDisplayAreaComponent,
    ModalComponent,
    MessengerMessageComponent,
    StepperComponent,
    FileDownloadComponent,
    EditorComponent,
    DateTimePickerComponent,
    FormGroupListComponent,
    FormGroupListItemComponent,
    SlidePanelContainerComponent,
    InfoCardComponent,
    InfoCardListComponent,
    CarouselPanelComponent,
    ChoiceSectionComponent,
    RecentActivityComponent,
    RateViolationsReasonSelectComponent,
    MessageListItemComponent,
    ShadowCollapseContainerComponent,
    InternalNotesComponent,
    AdditionListItemComponent,
  ],
  exports: [
    AddAnchorBlankDirective,
    AddBarComponent,
    FileUploadComponent,
    DatePickerComponent,
    CalendarComponent,
    ShiftDescriptionFilesComponent,
    ShiftGradeRateComponent,
    WizardStepComponent,
    DropdownSelectComponent,
    DropdownGroupingSelectComponent,
    TitleWithButtonComponent,
    WarningBoxComponent,
    ExpandableInputSectionComponent,
    ExpandableSectionComponent,
    UserStatusBarComponent,
    SectionComponent,
    TabsComponent,
    NotificationAlertComponent,
    DateRangeComponent,
    ProfileBadgeComponent,
    MessageComponent,
    NotesComponent,
    RadioButtonGroupComponent,
    CompactEventCalendarComponent,
    OptionalWidgetAreaComponent,
    PaginationBarComponent,
    EditableDisplayAreaComponent,
    ModalComponent,
    MessengerMessageComponent,
    StepperComponent,
    FileDownloadComponent,
    EditorComponent,
    DateTimePickerComponent,
    SlidePanelComponent,
    FormGroupListComponent,
    FormGroupListItemComponent,
    SlidePanelContainerComponent,
    InfoCardComponent,
    InfoCardListComponent,
    DetailCardComponent,
    StructuredInputComponent,
    CardWizardStepComponent,
    CardWizardComponent,
    DialogComponent,
    CarouselPanelComponent,
    ButtonGroupRadioButtonComponent,
    ChoiceSectionComponent,
    RecentActivityComponent,
    RateViolationsReasonSelectComponent,
    MessageListItemComponent,
    TippyDirective,
    ShadowCollapseContainerComponent,
    InternalNotesComponent,
    AdditionListItemComponent,
    FileComponent,
    TableComponent,
  ],
  providers: [
    {
      provide: TEMP_FILE_UPLOAD_SERVICE,
      useClass: TempFileUploadService,
    },
  ],
})
export class MoleculesModule {}
