<locumsnest-file-uploader
  [file]="file"
  [isDisabled]="isDisabled"
  [showFileDetails]="showFileDetails"
  [acceptFormats]="acceptFormats"
  [acceptFormatsUi]="acceptFormatsUi"
  [transparentTheme]="transparentTheme"
  (removeFile)="onResetUploadFileForm($event)"
>
  <div fileDetails>
    <div class="row">
      <div class="col-sm-6">
        <locumsnest-dropdown-grouping-select
          [ngrxFormControlState]="fileUploadForm.controls.type"
          [isDisabled]="isDisabled"
          [options]="fileTypes"
          [placeholder]="'Select file type'"
        />
      </div>
      <div class="col-sm-6">
        <locumsnest-date-picker
          class="date-picker"
          [ngrxFormControlState]="fileUploadForm.controls.expirationDate"
          [placeholder]="'Expiry date'"
          [errorMsg]="getErrorMsg(fileUploadForm.controls.expirationDate)"
          [isInvalid]="
            fileUploadForm.controls.expirationDate.isDirty &&
            fileUploadForm.controls.expirationDate.isInvalid
          "
        />
      </div>
    </div>
    <div class="row section-row">
      <div class="col-sm-6">
        @if (isTitleVisible) {
          <locumsnest-input-field
            [ngrxFormControlState]="fileUploadForm.controls.title"
            [disabled]="isDisabled"
            [placeholder]="'Filename'"
            [regularFont]="true"
          />
        }
      </div>
      <div class="col-sm-6 confirm-button">
        <locumsnest-button
          class="confirm-button"
          [buttonType]="'info'"
          [size]="'small'"
          [disabled]="fileUploadForm.isInvalid"
          (click)="onConfirmUploadFile()"
        >
          Confirm
        </locumsnest-button>
      </div>
    </div>
  </div>
</locumsnest-file-uploader>
