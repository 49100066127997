import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

type STATUS = 'ok' | 'alert' | 'warning';
type SIZE = 'xsmall' | 'small' | 'normal' | 'large';

@Component({
  selector: 'locumsnest-status-indicator',
  templateUrl: './status-indicator.component.html',
  styleUrls: ['./status-indicator.component.scss'],
  standalone: true,
  imports: [NgClass],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatusIndicatorComponent {
  @Output() statusChange = new EventEmitter();
  @Input() size: SIZE = 'normal';
  #status: STATUS = 'ok';

  get status() {
    return this.#status;
  }
  @Input()
  set status(status: STATUS) {
    if (status !== this.status) {
      this.#status = status;
      this.onStatusChange();
    }
  }

  onStatusChange() {
    this.statusChange.emit(this.status);
  }
}
