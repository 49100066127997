<locumsnest-input-label
  [input]="title"
  [size]="'small'"
  [displayRequiredIcon]="displayRequiredIcon"
/>

<locumsnest-input-field
  class="input-field"
  [noUnderline]="hasBorder"
  [hasBorder]="hasBorder"
  [type]="type"
  [placeholder]="placeholder"
  [disabled]="disabled"
  [transparentTheme]="transparentTheme"
  [errorMsg]="errorMsg"
  [isInvalid]="isInvalid"
  (inputEntered)="onInputChange($event)"
  [(ngModel)]="value"
/>
