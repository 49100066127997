@if (documentList?.length === 0) {
  <div class="document-row">No Available Documents</div>
}
@for (doc of documentList; track trackByDocument(i, doc); let i = $index) {
  <div class="document-row">
    <span title="{{ doc.display }}">{{ doc.display | textTruncate }}</span>
    @if (showExpirationDate) {
      <span class="date-icon">
        <locumsnest-status-indicator
          [status]="getStatusIndicator(doc.expirationDate)"
          [size]="'xsmall'"
        />
      </span>
    }
    @if (showExpirationDate) {
      <div class="document-row--expirationDate">
        @if (doc.expirationDate === null) {
          <span>
            <locumsnest-date-picker
              class="date-picker"
              [placeholder]="'Expiry date'"
              [showCalendarIcon]="true"
              [extraClass]="'hideInputField'"
              [showConfirmButton]="true"
              [confirmButtonText]="'Update'"
              [datePickerLabelText]="'never'"
              (confirmButtonClicked)="onConfirmButtonClick($event, doc)"
            />
          </span>
        }
        @if (getStatusIndicator(doc.expirationDate) === 'alert' && canChangeDocument) {
          <span class="renew">
            <span class="renew">
              <locumsnest-date-picker
                class="date-picker"
                [placeholder]="'Expiry date'"
                [showCalendarIcon]="true"
                [extraClass]="'hideInputField'"
                [showConfirmButton]="true"
                [confirmButtonText]="'Renew'"
                [datePickerLabelText]="'Renew'"
                [showResetButton]="true"
                (confirmButtonClicked)="onConfirmButtonClick($event, doc)"
                (resetButtonClicked)="onResetButtonClick(doc)"
              />
            </span>
          </span>
        }
        @if (
          (getStatusIndicator(doc.expirationDate) === 'alert' && !canChangeDocument) ||
          (doc.expirationDate !== null &&
            (getStatusIndicator(doc.expirationDate) === 'ok' ||
              getStatusIndicator(doc.expirationDate) === 'warning'))
        ) {
          <span>
            <locumsnest-date-picker
              class="date-picker"
              [placeholder]="'Expiry date'"
              [showCalendarIcon]="true"
              [extraClass]="'hideInputField'"
              [showConfirmButton]="true"
              [showResetButton]="true"
              [confirmButtonText]="'Update'"
              [datePickerLabelText]="formatDate(doc.expirationDate)"
              (confirmButtonClicked)="onConfirmButtonClick($event, doc)"
              (resetButtonClicked)="onResetButtonClick(doc)"
            />
          </span>
        }
      </div>
    }
    @if (doc.ownerName) {
      <div class="document-row--owner">
        {{ doc.ownerName }}
      </div>
    }
    @if (isCertificate(doc)) {
      <div class="document-row--certificate">
        <a href="{{ doc.url }}" target="_blank">View File</a>
      </div>
    }
    @if (isReference(doc)) {
      <div class="document-row--reference">
        <a href="javascript:void(0)" (click)="openModal(doc)">View</a>
      </div>
    }
    @if (allowFileCopy && !doc.isCopied) {
      <div class="document-row--copy">
        <a href="javascript:void(0)" (click)="onCopyButtonClick(doc)">Copy to safebox</a>
      </div>
    }
    @if (allowFileCopy && doc.isCopied) {
      <div class="document-row--copy">Copied to safebox</div>
    }
    @if (showExpirationDate && canDeleteDocument) {
      <div class="document-row--delete" (click)="onDeleteButtonClick(doc)"></div>
    }
  </div>
}

<locumsnest-dialog [show]="showModal" [title]="selectedDoc?.display" (close)="closeModal()">
  <ng-container body>
    @if (selectedDoc?.original.refereeName && selectedDoc?.original.refereeName !== '') {
      <div><b>Referee:</b> {{ selectedDoc.original.refereeName }}</div>
    }
    <p></p>
    @if (selectedDoc && selectedDoc.text !== '') {
      <p>{{ selectedDoc.text }}</p>
    } @else {
      <p>No description available</p>
    }
  </ng-container>
</locumsnest-dialog>
