import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  Output,
} from '@angular/core';
import { FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';

import { InputFieldComponent } from '../../atoms';
import { InputLabelComponent } from '../../atoms/input-label/input-label.component';
import { InputWrapperWithChangeDetection } from '../../core/input-wrapper';

@Component({
  selector: 'locumsnest-structured-input',
  templateUrl: './structured-input.component.html',
  styleUrls: ['./structured-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => StructuredInputComponent),
      multi: true,
    },
  ],
  standalone: true,
  imports: [InputFieldComponent, InputLabelComponent, FormsModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StructuredInputComponent extends InputWrapperWithChangeDetection {
  @Input() title: string;
  @Input() displayRequiredIcon = false;
  @Input() hasBorder = true;
  @Input() placeholder: string;
  @Input() isRequired = false;
  @Input() isInvalid = false;
  @Input() disabled: boolean;
  @Input() transparentTheme = false;
  @Input() errorMsg: string;
  @Input() type: 'text' | 'number' | 'password' | 'date' | 'datetime-local';
  @Output() inputChange: EventEmitter<Event> = new EventEmitter<Event>();

  public onInputChange(event: Event) {
    this.inputChange.emit(event);
  }
}
