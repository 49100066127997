import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'locumsnest-icon-tip',
  templateUrl: './icon-tip.component.html',
  styleUrls: ['./icon-tip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgClass],
})
export class IconTipComponent {
  @Input() status:
    | 'info'
    | 'warning'
    | 'error'
    | 'cancellation'
    | 'unfilled'
    | 'all-filled'
    | 'some-filled'
    | 'approved'
    | 'cascaded'
    | 'bank-holiday';
  @Input() displayText: string;
  @Input() coloredText = false;
  @Input() bellAnimation = false;
}
