import { NgTemplateOutlet } from '@angular/common';
import {
  AfterContentInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  OnDestroy,
  QueryList,
} from '@angular/core';
import { Subject, takeUntil } from 'rxjs';

import { CardWizardStepComponent } from '../card-wizard-step/card-wizard-step.component';

@Component({
  selector: 'locumsnest-card-wizard',
  templateUrl: './card-wizard.component.html',
  styleUrls: ['./card-wizard.component.scss'],
  standalone: true,
  imports: [NgTemplateOutlet],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardWizardComponent implements AfterContentInit, OnDestroy {
  @ContentChildren(CardWizardStepComponent, { descendants: true })
  steps: QueryList<CardWizardStepComponent>;

  private destroy$ = new Subject<void>();

  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  ngAfterContentInit() {
    this.steps.changes.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.stateChanged();
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private stateChanged() {
    this.changeDetectorRef.markForCheck();
  }
}
