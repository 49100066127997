import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgrxFormsModule } from 'ngrx-forms';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

import { AtomsModule } from '../atoms/atoms.module';
import { MoleculesModule } from '../molecules/molecules.module';
import { CarouselComponent } from './carousel/locumsnest-carousel';
import { ContactFormWidgetComponent } from './contact-form-widget/contact-form-widget.component';
import { DocumentManagerComponent } from './document-manager/document-manager.component';
import { ExpiringDocumentUploaderComponent } from './expiring-document-uploader/expiring-document-uploader.component';
import { FileUploaderComponent } from './file-uploader/file-uploader.component';
import { MessageListComponent } from './message-list/message-list.component';
import { MessengerComponent } from './messenger/messenger.component';
import { TemplateSaveModalComponent } from './template-save-modal/template-save-modal.component';

@NgModule({
  imports: [
    CommonModule,
    AtomsModule,
    MoleculesModule,
    FormsModule,
    NgrxFormsModule,
    InfiniteScrollModule,
    FileUploaderComponent,
    DocumentManagerComponent,
    ExpiringDocumentUploaderComponent,
  ],
  declarations: [
    TemplateSaveModalComponent,
    ContactFormWidgetComponent,
    MessageListComponent,
    MessengerComponent,
    CarouselComponent,
  ],
  exports: [
    FileUploaderComponent,
    TemplateSaveModalComponent,
    ContactFormWidgetComponent,
    MessageListComponent,
    MessengerComponent,
    ExpiringDocumentUploaderComponent,
    DocumentManagerComponent,
    CarouselComponent,
  ],
})
export class OrganismsModule {}
