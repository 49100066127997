import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbTimepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { TippyDirective } from '@ngneat/helipopper';
import { NgrxFormsModule } from 'ngrx-forms';

import { PipesModule } from '../pipes/pipes.module';
import { ActionButtonComponent } from './action-button/action-button.component';
import { ActionIconButtonComponent } from './action-icon-button/action-icon-button.component';
import { AlertContainerComponent } from './alert-container/alert-container.component';
import { AvatarComponent } from './avatar/avatar.component';
import { ButtonComponent } from './button/button.component';
import { CheckBoxComponent } from './check-box/check-box.component';
import { CircularButtonComponent } from './circular-button/circular-button.component';
import { CountIconComponent } from './count-icon/count-icon.component';
import { EllipsisComponent } from './ellipsis/ellipsis.component';
import { HelpTextComponent } from './help-text/help-text.component';
import { IconButtonComponent } from './icon-button/icon-button.component';
import { IconTipComponent } from './icon-tip/icon-tip.component';
import { IconComponent } from './icon/icon.component';
import { InfoBannerComponent } from './info-banner/info-banner.component';
import { InputFieldComponent } from './input-field/input-field.component';
import { InputLabelComponent } from './input-label/input-label.component';
import { LockButtonComponent } from './lock-button/lock-button.component';
import { NumberBadgeComponent } from './number-badge/number-badge.component';
import { ProfilePictureComponent } from './profile-picture/profile-picture.component';
import { ProgressBarComponent } from './progress-bar/progress-bar.component';
import { RadioButtonComponent } from './radio-button/radio-button.component';
import { SectionHeaderTitleComponent } from './section-header-title/section-header-title.component';
import { SectionRowComponent } from './section-row/section-row.component';
import { SectionTitleComponent } from './section-title/section-title.component';
import { SmallTipOwlComponent } from './small-tip-owl/small-tip-owl.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { StatusIndicatorComponent } from './status-indicator/status-indicator.component';
import { StatusProgressBarComponent } from './status-progress-bar/status-progress-bar.component';
import { StepIndicatorComponent } from './step-indicator/step-indicator.component';
import { StepComponent } from './step/step.component';
import { TabComponent } from './tab/tab.component';
import { TagLabelComponent } from './tag-label/tag-label.component';
import { TextAreaComponent } from './text-area/text-area.component';
import { TimeFieldComponent } from './time-field/time-field.component';
import { ToastComponent } from './toast/toast.component';
import { ToggleComponent } from './toggle/toggle.component';
import { UserProfileComponent } from './user-profile/user-profile.component';

@NgModule({
  declarations: [
    NumberBadgeComponent,
    StepIndicatorComponent,
    SmallTipOwlComponent,
    SectionHeaderTitleComponent,
    UserProfileComponent,
    AlertContainerComponent,
    TabComponent,
    SectionRowComponent,
    ProfilePictureComponent,
    InfoBannerComponent,
    CircularButtonComponent,
    StatusProgressBarComponent,
    CountIconComponent,
    StepComponent,
    SpinnerComponent,
    LockButtonComponent,
    TimeFieldComponent,
    ActionIconButtonComponent,
  ],
  exports: [
    InputLabelComponent,
    SectionHeaderTitleComponent,
    InputFieldComponent,
    NumberBadgeComponent,
    CheckBoxComponent,
    ActionButtonComponent,
    ProgressBarComponent,
    StepIndicatorComponent,
    SmallTipOwlComponent,
    ToggleComponent,
    StatusIndicatorComponent,
    UserProfileComponent,
    SectionTitleComponent,
    AlertContainerComponent,
    TabComponent,
    SectionRowComponent,
    IconButtonComponent,
    ProfilePictureComponent,
    HelpTextComponent,
    InfoBannerComponent,
    TagLabelComponent,
    CircularButtonComponent,
    TextAreaComponent,
    RadioButtonComponent,
    StatusProgressBarComponent,
    CountIconComponent,
    AvatarComponent,
    ToastComponent,
    StepComponent,
    EllipsisComponent,
    SpinnerComponent,
    LockButtonComponent,
    IconComponent,
    TimeFieldComponent,
    ActionIconButtonComponent,
    ButtonComponent,
    IconTipComponent,
  ],
  imports: [
    SectionTitleComponent,
    CheckBoxComponent,
    TagLabelComponent,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgrxFormsModule,
    RouterModule,
    PipesModule,
    NgbTimepickerModule,
    ToastComponent,
    EllipsisComponent,
    TextAreaComponent,
    TippyDirective,
    ButtonComponent,
    ToggleComponent,
    ProgressBarComponent,
    RadioButtonComponent,
    IconButtonComponent,
    IconComponent,
    InputFieldComponent,
    ActionButtonComponent,
    AvatarComponent,
    IconTipComponent,
    InputLabelComponent,
    HelpTextComponent,
    StatusIndicatorComponent,
  ],
})
export class AtomsModule {}
