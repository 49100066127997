import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { IUploadFileFormState } from 'apps/hospital-admin/src/app/profile/+state/interfaces';
import {
  AbstractControlState,
  FormControlState,
  FormGroupState,
  NgrxFormsModule,
} from 'ngrx-forms';

import { ButtonComponent, InputFieldComponent, ISelectOption } from '@locumsnest/components';
import { parseValueErrors } from '@locumsnest/core/src';

import { DatePickerComponent } from '../../molecules/date-picker/date-picker.component';
import { DropdownGroupingSelectComponent } from '../../molecules/dropdown-grouping-select/dropdown-grouping-select.component';
import { FileUploaderComponent } from '../file-uploader/file-uploader.component';

@Component({
  selector: 'locumsnest-expiring-document-uploader',
  templateUrl: './expiring-document-uploader.component.html',
  styleUrls: ['./expiring-document-uploader.component.scss'],
  standalone: true,
  imports: [
    NgrxFormsModule,
    FileUploaderComponent,
    DropdownGroupingSelectComponent,
    DatePickerComponent,
    InputFieldComponent,
    ButtonComponent,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExpiringDocumentUploaderComponent {
  @Input() file: FormControlState<string>;
  @Input() isDisabled: boolean;
  @Input() fileTypes: ISelectOption[];
  @Input() fileUploadForm: FormGroupState<IUploadFileFormState>;
  @Input() isTitleVisible: boolean;
  @Input() showFileDetails: boolean;
  @Input() acceptFormats: string;
  @Input() acceptFormatsUi: string;
  @Input() transparentTheme: boolean;

  @Output() resetUploadFileForm = new EventEmitter<string>();
  @Output() confirmUploadFile = new EventEmitter<void>();

  constructor() {}

  public onResetUploadFileForm(event) {
    this.showFileDetails = true;
    this.resetUploadFileForm.emit(event);
  }

  public onConfirmUploadFile() {
    this.confirmUploadFile.emit();
  }

  public getErrorMsg(control: AbstractControlState<any>) {
    return control.isDirty ? parseValueErrors(control.errors) : '';
  }
}
