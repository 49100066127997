<div class="status">
  <div
    class="status__icon"
    [ngClass]="{
      'status__icon--unFilled': status === 'unfilled',
      'status__icon--allFilled': status === 'all-filled',
      'status__icon--someFilled': status === 'some-filled',
      'status__icon--cancellation': status === 'cancellation',
      'status__icon--error': status === 'error',
      'status__icon--info': status === 'info',
      'status__icon--warning': status === 'warning',
      'status__icon--approved': status === 'approved',
      'status__icon--cascaded': status === 'cascaded',
      'status__icon--bank-holiday': status === 'bank-holiday',
      'status__icon--bell-animation': bellAnimation
    }"
  ></div>
  <p
    class="status-text"
    [ngClass]="{
      'status-text--error': coloredText && status === 'error',
      'status-text--info': coloredText && status === 'info',
      'status-text--warning': coloredText && status === 'warning'
    }"
  >
    {{ displayText }}
    <ng-content />
  </p>
</div>
