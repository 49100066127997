@if (show) {
  <div id="reference-modal" class="modal">
    <div class="background-close" (click)="closeModal()"></div>
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ title }}</h5>
          <button class="close" type="button" (click)="closeModal()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <ng-content select="[body]" />
        </div>
        @if (footer) {
          <div class="modal-footer">
            <ng-content select="[footer]" />
          </div>
        }
      </div>
    </div>
  </div>
}
