import { Actions, concatLatestFrom, createEffect } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { isEmpty } from 'lodash-es';
import {
  concat,
  EMPTY,
  exhaustMap,
  filter,
  interval,
  map,
  Observable,
  of,
  switchMap,
  take,
  tap,
} from 'rxjs';

import { ofSignalType } from '@locumsnest/core/src';
import { Time } from '@locumsnest/core/src/lib/helpers';
import { BaseEffects } from '@locumsnest/core/src/lib/ngrx/effect';
import { FilterViewsService, IFilterViews, UpdateOneMessage } from '@locumsnest/filter-views/src';

import { alertHandler } from '../../../../../../../apps/hospital-admin/src/app/core/+state/ui/ui.adapter';
import { IFilterValues } from '../../../../../../../apps/hospital-admin/src/app/interfaces/api/filter-views-entity';
import { ITabSignals } from './adapter';
import { TabsService } from './tabs.adapter';
import { ISearchFilterForm } from './tabs.tokens';

export abstract class BaseSearchFilterFormEffects extends BaseEffects {
  protected abstract searchFilterFormService: ISearchFilterForm<unknown, unknown>;
  protected abstract tabsService: TabsService;
  protected abstract filterViewsService: FilterViewsService;
  // ITabSignals<F>; BaseSearchFilterFormEffects<F>
  // when every searchFilterFormService has its own adapter
  protected abstract tabSignals: ITabSignals<any>;
  protected abstract pageKey: string;

  constructor(protected actions$: Actions) {
    super();
  }

  initializeTabs$ = createEffect(() =>
    this.actions$.pipe(
      ofSignalType(this.tabSignals.InitializeTabsSignal),
      filter((action) => action.payload.page === this.pageKey),
      concatLatestFrom(({ payload }) => [
        this.filterViewsService.getAllByPage(this.pageKey),
        this.searchFilterFormService.getFilteredQueryParams(),
        this.filterViewsService.getHomeFilter(payload.page),
      ]),
      exhaustMap(([action, tabs, paramsState, homeFilter]) => {
        const { alertsNamespace, filterNamespace } = action.payload;
        let activeTabId: number | null = null;
        let activeTabFilters: IFilterValues | Partial<IFilterValues> =
          this.searchFilterFormService.deserialize(paramsState);

        let selectTab: IFilterViews | undefined;
        if (isEmpty(paramsState)) selectTab = homeFilter;
        else
          selectTab = tabs.find((tab) =>
            this.searchFilterFormService.isEqual(tab.filters, activeTabFilters),
          );

        if (selectTab) {
          activeTabId = selectTab.id;
          activeTabFilters = selectTab.filters;
        }

        this.tabsService.updateActiveTab(activeTabId);
        this.searchFilterFormService.enableFiltersForRoute(activeTabFilters);
        return concat(
          this.searchFilterFormService.updateFilterFormAction(activeTabFilters),
          this.searchFilterFormService.submitSearchFilterFormAction(
            filterNamespace,
            alertsNamespace,
          ),
        );
      }),
    ),
  );

  addHomeTab$ = createEffect(() =>
    this.actions$.pipe(
      ofSignalType(this.tabSignals.AddHomeTabSignal),
      filter((action) => action.payload.page === this.pageKey),
      concatLatestFrom(() => [this.searchFilterFormService.getDynamicInitialState()]),
      exhaustMap(([action, filters]) => {
        const { filterNamespace, alertsNamespace, page } = action.payload;

        const filterKeys = this.searchFilterFormService.getNonNullFilterKeys(filters);
        return concat(
          this.searchFilterFormService.updateFilterFormAction(filterKeys),
          this.filterViewsService.addHomeTab(filterKeys, page),
          of(new this.tabSignals.InitializeTabsSignal({ filterNamespace, alertsNamespace, page })),
        );
      }),
    ),
  );

  revertToDefaultsHomeFilters$ = createEffect(() =>
    this.actions$.pipe(
      ofSignalType(this.tabSignals.RevertToDefaultsHomeFiltersSignal),
      filter((action) => action.payload.page === this.pageKey),
      concatLatestFrom(() => [this.searchFilterFormService.getDynamicInitialState()]),
      exhaustMap(([action, filters]) => {
        const { filterNamespace, alertsNamespace, page } = action.payload;
        const filterKeys = this.searchFilterFormService.getFilterKeys(filters);
        this.searchFilterFormService.enableFiltersForRoute(filterKeys);

        return concat(
          this.searchFilterFormService.updateFilterFormAction(filterKeys),
          interval(1000).pipe(
            take(1),
            map(() => new this.tabSignals.SaveActiveTabFiltersSignal({ page })),
          ),
          this.searchFilterFormService.submitSearchFilterFormAction(
            filterNamespace,
            alertsNamespace,
          ),
        );
      }),
    ),
  );

  addActiveTab$ = createEffect(() =>
    this.actions$.pipe(
      ofSignalType(this.tabSignals.AddActiveTabSignal),
      filter((action) => action.payload.page === this.pageKey),
      concatLatestFrom(() => [
        this.searchFilterFormService.deserializeForBackend(),
        this.filterViewsService.getAllByPage(this.pageKey),
        this.tabsService.getActiveTabId(),
        this.searchFilterFormService.getNotNullDeserializedQueryParams(),
      ]),
      exhaustMap(([action, filters, tabs, activeTabId, params]) => {
        const name = this.createTabName(tabs);

        return concat(
          this.filterViewsService
            .addTab(name, filters, action.payload.page)
            .pipe(tap((res) => this.tabsService.updateActiveTab(res.payload.entity.id))),
          of(
            new UpdateOneMessage({
              entity: {
                id: +activeTabId,
                changes: {
                  localFilters: params,
                  lastUpdate: Time.formatISODate().toString(),
                },
              },
            }),
          ),
        );
      }),
    ),
  );

  updateActiveTab$ = createEffect(() =>
    this.actions$.pipe(
      ofSignalType(this.tabSignals.UpdateActiveTabSignal),
      filter((action) => action.payload.page === this.pageKey),
      concatLatestFrom((action) => [
        this.filterViewsService.getOne(action.payload.id),
        this.tabsService.getActiveTabId(),
        this.searchFilterFormService.getNotNullDeserializedQueryParams(),
      ]),
      switchMap(([action, tab, activeTabId, params]) => {
        const { filterNamespace, alertsNamespace } = action.payload;
        this.tabsService.updateActiveTab(tab.id);

        return concat(
          this.searchFilterFormService.updateFilterFormAction(tab.localFilters || tab.filters),
          this.searchFilterFormService.submitSearchFilterFormAction(
            filterNamespace,
            alertsNamespace,
          ),
          of(
            new UpdateOneMessage({
              entity: {
                id: +activeTabId,
                changes: {
                  localFilters: params,
                  lastUpdate: Time.formatISODate().toString(),
                },
              },
            }),
          ),
        );
      }),
    ),
  );

  saveActiveTabFilters$ = createEffect(() =>
    this.actions$.pipe(
      ofSignalType(this.tabSignals.SaveActiveTabFiltersSignal),
      filter((action) => action.payload.page === this.pageKey),
      concatLatestFrom(() => [
        this.filterViewsService.getAllByPage(this.pageKey),
        this.tabsService.getActiveTabId(),
        this.searchFilterFormService.deserializeForBackend(),
      ]),
      switchMap(([action, tabs, activeTabId, filters]) => {
        if (!activeTabId) {
          if (tabs.length >= 6)
            return alertHandler({
              message: `The maximum number of tabs that can be saved has been reached. 
                        You must first delete an existing one in order 
                        to save the current filters on a new one.`,
              type: 'error',
            });
          const name = this.createTabName(tabs);
          return this.filterViewsService
            .addTab(name, filters, action.payload.page)
            .pipe(tap((res) => this.tabsService.updateActiveTab(res.payload.entity.id)));
        } else return this.filterViewsService.saveActiveTab(activeTabId, filters);
      }),
    ),
  );

  updateTabName$ = createEffect(() =>
    this.actions$.pipe(
      ofSignalType(this.tabSignals.UpdateTabNameSignal),
      filter((action) => action.payload.page === this.pageKey),
      concatLatestFrom(() => this.filterViewsService.getAllByPage(this.pageKey)),
      switchMap(([action, tabs]) => {
        const { name, id } = action.payload;
        const currentTab = tabs.find((tab) => tab.id === id);

        if (currentTab.name.toLowerCase() === name.toLowerCase()) {
          this.tabsService.updateEditTab(null);
          return EMPTY;
        }

        const nameExists = !!tabs
          .filter((tab) => tab.id !== id)
          .find((tab) => tab.name.toLowerCase().trim() === name.toLowerCase().trim());

        if (nameExists) {
          return alertHandler({
            message: `Name already exists. Please choose a different one.`,
            type: 'error',
          });
        }

        this.tabsService.updateEditTab(null);
        return this.filterViewsService.updateTab(id, name);
      }),
    ),
  );

  removeTab$ = createEffect(() =>
    this.actions$.pipe(
      ofSignalType(this.tabSignals.RemoveTabSignal),
      filter((action) => action.payload.page === this.pageKey),
      concatLatestFrom(() => [
        this.tabsService.getActiveTabId(),
        this.filterViewsService.getAllByPage(this.pageKey),
      ]),
      exhaustMap(([action, activeTabId, tabs]) => {
        const { id, filterNamespace, alertsNamespace } = action.payload;
        const actions$: Observable<Action>[] = [this.filterViewsService.removeOne(id)];

        if (id === activeTabId) {
          const activeTabIndex = tabs.findIndex((tab) => tab.id === activeTabId);
          const previousTab = tabs[activeTabIndex - 1];
          const filters = this.searchFilterFormService.getFilterKeys(
            previousTab.localFilters || previousTab.filters,
          );
          this.searchFilterFormService.enableFiltersForRoute(filters);
          this.tabsService.updateActiveTab(previousTab.id);
          actions$.push(
            this.searchFilterFormService.updateFilterFormAction(filters),
            this.searchFilterFormService.submitSearchFilterFormAction(
              filterNamespace,
              alertsNamespace,
            ),
          );
        }
        return concat(...actions$);
      }),
    ),
  );

  private createTabName(tabs: IFilterViews[]): string {
    const defaultName = 'New Tab';
    let name = defaultName;
    let counter = 1;
    const currentTabsNames = tabs.map((t) => t.name);
    while (currentTabsNames.includes(name)) {
      name = `${defaultName}${counter++}`;
      if (counter >= 10) break;
    }
    return name;
  }
}
