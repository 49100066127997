import { ChangeDetectionStrategy, Component, EventEmitter, input, Output } from '@angular/core';

import { IconComponent } from '../icon/icon.component';

@Component({
  selector: 'locumsnest-tag-label',
  templateUrl: './tag-label.component.html',
  styleUrls: ['./tag-label.component.scss'],
  standalone: true,
  imports: [IconComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    '[class.new]': 'isNew()',
    '[class.disabled]': 'isDisabled()',
    '[class.is-active]': 'isActive()',
    '[class.has-error]': 'hasError()',
  },
})
export class TagLabelComponent {
  isNew = input(false);
  isDisabled = input(false);
  isActive = input(false);
  hasError = input(false);
  @Output() remove = new EventEmitter<Event>();

  public onRemove(event: Event) {
    if (!this.isDisabled()) {
      this.remove.emit(event);
    }
  }
}
