<ng-content />

@if (!isNew()) {
  <locumsnest-icon
    class="remove-icon"
    iconName="bin-v2"
    width="15"
    height="15"
    [class.has-error]="hasError()"
    (click)="onRemove($event)"
  />
}
