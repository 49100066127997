<label
  class="input-title"
  [attr.for]="for"
  [ngClass]="{
    'text-danger': isRequired,
    'input-title--small': size === 'small',
    'input-title--medium': size === 'medium',
    'input-title--xsmall': size === 'xsmall'
  }"
  [ngStyle]="{ 'padding-left': displayRequiredIcon ? '0px' : '12px' }"
>
  @if (displayRequiredIcon) {
    <small>*</small>
  }
  {{ input }}
</label>
