import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'locumsnest-help-text',
  templateUrl: './help-text.component.html',
  styleUrls: ['./help-text.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HelpTextComponent {
  @Input() helpText: string;
}
