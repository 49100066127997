import { Type } from '@angular/core';
import { createSelector, DefaultProjectorFn, MemoizedSelector } from '@ngrx/store';
import { get } from 'lodash-es';
import { Observable } from 'rxjs/internal/Observable';

import {
  Action,
  ActionNamespace,
  ActionType,
  MessageableFactory,
  SignalableFactory,
} from '@locumsnest/core/src/lib/ngrx';
import { createSignalConverter } from '@locumsnest/core/src/lib/ngrx/helpers/effects';

import {
  ISelectorService,
  serviceSelectors,
} from './../../../../../../core/src/lib/ngrx/decorators/service';
import { IWizardState, IWizardStepState } from './interfaces';

export interface IBaseWizardEffects {
  initializeWizard: () => void;
}

export interface IBaseWizardService<F extends string> extends ISelectorService {
  getActiveStep: () => Observable<number>;
  getSteps: () => Observable<Record<number, IWizardStepState>>;
  getStepComplete: () => Observable<boolean>;
  initialize: () => void;
  updateActiveStep: (step: number) => void;
  completeStep: (step: number) => void;
  getCompleteStepMessage(
    step: number,
  ): Action<
    ActionType<F, ActionNamespace.MESSAGE, 'Complete Step Message'>,
    { activeStep: number }
  >;
  getUpdateActiveStepMessage(
    activeStep: number,
  ): Action<
    ActionType<F, ActionNamespace.MESSAGE, 'Update Active Step Message'>,
    { activeStep: number }
  >;
  getInitializeMessage(): Action<
    ActionType<F, ActionNamespace.MESSAGE, 'Initialize Card Wizard'>,
    { activeStep: number }
  >;
}
export type IWizardEffects<T> = T & IBaseWizardEffects;
export function createWizardAdapter<F extends string>(
  signalableFactory: SignalableFactory<F>,
  messageableFactory: MessageableFactory<F>,
) {
  //region signals

  class InitializeCardWizardSignal extends signalableFactory.create<
    'Initialize Card Wizard Signal',
    {}
  >('Initialize Card Wizard Signal') {}

  class UpdateActiveStepSignal extends signalableFactory.create<
    'Update Active Step Signal',
    { activeStep: number }
  >('Update Active Step Signal') {}

  class CompleteStepSignal extends signalableFactory.create<
    'Complete Step Signal',
    { step: number }
  >('Complete Step Signal') {}

  //endRegion

  //region messages
  class InitializeCardWizardMessage extends messageableFactory.create<'Initialize Card Wizard', {}>(
    'Initialize Card Wizard',
  ) {}

  class UpdateActiveStepMessage extends messageableFactory.create<
    'Update Active Step Message',
    { activeStep: number }
  >('Update Active Step Message') {}

  class CompleteStepMessage extends messageableFactory.create<
    'Complete Step Message',
    { step: number }
  >('Complete Step Message') {}

  //endRegion

  function wizardEffects<T>(cls: Type<T>): Type<IWizardEffects<T>> {
    cls.prototype.initializeWizard = function () {
      this.initializeCardWizardSignal$ = createSignalConverter(
        this.actions$,
        InitializeCardWizardSignal,
        InitializeCardWizardMessage,
      );

      this.updateActiveStepSignal$ = createSignalConverter(
        this.actions$,
        UpdateActiveStepSignal,
        UpdateActiveStepMessage,
      );

      this.completeStepSignal$ = createSignalConverter(
        this.actions$,
        CompleteStepSignal,
        CompleteStepMessage,
      );
    };
    return cls as Type<T & { initializeWizard: () => void }>;
  }

  const INITIAL_STEP_STATE: IWizardStepState = {
    complete: false,
  };

  const INITIAL_STATE: IWizardState = {
    activeStep: 1,
    steps: {},
  };

  function createReducer(customInitialState = INITIAL_STATE) {
    const reducer = (
      state: IWizardState = customInitialState,
      action: InitializeCardWizardMessage | UpdateActiveStepMessage | CompleteStepMessage,
    ) => {
      switch (action.type) {
        case InitializeCardWizardMessage.TYPE: {
          return {
            ...INITIAL_STATE,
          };
        }
        case UpdateActiveStepMessage.TYPE: {
          state = {
            ...state,
            activeStep: (action as UpdateActiveStepMessage).payload.activeStep,
          };
          break;
        }
        case CompleteStepMessage.TYPE: {
          const step = (action as CompleteStepMessage).payload.step;
          const steps = state.steps;
          const newStepState = { ...get(steps, step, INITIAL_STEP_STATE), complete: true };

          state = {
            ...state,
            steps: { ...steps, [step]: newStepState },
          };

          break;
        }
        default:
          break;
      }

      return state;
    };

    return reducer;
  }

  function getSelectors(
    featureSelector: MemoizedSelector<object, IWizardState, DefaultProjectorFn<IWizardState>>,
  ) {
    const selectActiveStep = createSelector(featureSelector, (state) => state.activeStep);
    const selectSteps = createSelector(featureSelector, (state) => state.steps);
    const selectStepComplete = (stepIndex) =>
      createSelector(selectSteps, (steps) => steps[stepIndex].complete);
    return {
      selectActiveStep,
      selectSteps,
      selectStepComplete,
    };
  }

  function getSignals() {
    return {
      InitializeCardWizardSignal,
      UpdateActiveStepSignal,
      CompleteStepSignal,
    };
  }

  function getMessages() {
    return {
      InitializeCardWizardMessage,
      UpdateActiveStepMessage,
      CompleteStepMessage,
    };
  }
  function getCompleteStepMessage(step: number) {
    return new CompleteStepMessage({ step });
  }
  function getUpdateActiveStepMessage(activeStep: number) {
    return new UpdateActiveStepMessage({ activeStep });
  }
  function getInitializeMessage() {
    new InitializeCardWizardMessage({});
  }
  const wizardService = (
    featureSelector: MemoizedSelector<object, IWizardState, DefaultProjectorFn<IWizardState>>,
  ) =>
    function (cls) {
      const selectors = getSelectors(featureSelector);
      cls = serviceSelectors(selectors)(cls);
      cls.prototype.initialize = function () {
        this.store.dispatch(new InitializeCardWizardMessage({}));
      };
      cls.prototype.updateActiveStep = function (activeStep: number) {
        this.store.dispatch(new UpdateActiveStepMessage({ activeStep }));
      };
      cls.prototype.completeStep = function (step: number) {
        this.store.dispatch(new CompleteStepMessage({ step }));
      };
      cls.prototype.getCompleteStepMessage = getCompleteStepMessage;
      cls.prototype.getUpdateActiveStepMessage = getUpdateActiveStepMessage;
      cls.prototype.getInitializeMessage = getInitializeMessage;
      return cls;
    };
  return {
    createReducer,
    getSignals,
    getMessages,
    getSelectors,
    wizardEffects,
    wizardService,
  };
}
