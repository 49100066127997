@if (showLeftArrow()) {
  <div class="arrow-left"></div>
}
@if (title) {
  <div class="title">{{ title() }}</div>
}

<div class="text">
  Beware of shift times overlap - select a shift pattern that is safe and compliant with working
  time regulations
</div>

@if (stepTwoActive()) {
  <div class="text" style="text-align: center">
    @if (hasSelectedListing()) {
      -deselect to apply changes to all-
    } @else {
      - choose individual shifts to modify -
    }
  </div>
}
@if (errorCount()) {
  <locumsnest-icon-tip
    class="review"
    status="error"
    [displayText]="
      errorCount() + ' ' + (errorCount() === 1 ? 'shift needs review' : 'shifts need review')
    "
    [coloredText]="true"
  />
}
@if (jobListings().length > 0) {
  @for (listing of jobListings(); track listing.id) {
    <locumsnest-tag-label
      [isActive]="listing.selectedListing"
      [hasError]="listing.hasAlert"
      [isDisabled]="isDisabled()"
      (click)="onJobListingSelected(listing.id)"
      (remove)="onJobListingRemove($event, listing.id)"
    >
      {{ listing.startTime | date: timeFormat }} to {{ listing.endTime | date: timeFormat }} |
      {{ listing.startTime | date: dateFormat }}
    </locumsnest-tag-label>
  }
}

@if (showRightArrow()) {
  <div class="arrow-right"></div>
}
